// Reset
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

table {
  border-collapse: collapse;
  border-spacing: 0; }


// Variables
// Body
$body-bg: #f5f8fa;


// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Bootstrap
@import '~bootstrap/scss/bootstrap';

* {
  font-family: 'Lato';
}

section {
  width: 100vw;
  height: 100vh;
}

app-counter {
  background-color: #fa96a2;
  color: #ffffff;
}

app-chart {
  background-color: #a3b2ff;
}

app-info {
  background-color: rgb(97, 168, 251);
}

#fp-nav > ul > li > a > span {
  background: rgb(255, 255, 255);
}


@media only screen and (max-width: 600px) {
  #fp-section2, #fp-nav {
      display: none;
  }
}
